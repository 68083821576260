
import { defineComponent, PropType } from "vue-demi";

export default defineComponent({
  name: 'Autopost',
  props: {
    autopost: {
      type: Object // as PropType<Autopost.Dto>,
      // required: true
    }
  }
})
